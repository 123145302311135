/*******************************
         Site Overrides
*******************************/

/*--------------
    Rounded
---------------*/

.ui.rounded.input, .ui.rounded.input input, .rounded .ui.input, {
  border-radius: @40px;
}