/*******************************
        User Overrides
*******************************/


.rounded  {
        .ui.fluid.search.selection.dropdown {
                        border-radius: @25px;
        }
        .ui.search.dropdown .menu  {
                    border-bottom-left-radius: @25px !important;
                    border-bottom-right-radius: @25px;
                padding: 0.7em 0;
        }
}