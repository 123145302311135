/*******************************
         Site Overrides
*******************************/

/*--------------
    Rounded
---------------*/

.ui.rounded.button, .rounded .ui.button {
  border-radius: @40px;
}